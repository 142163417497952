import Vue from "vue";
import VueI18n from "vue-i18n";

// Core localisation language list 
import { locale as cs } from "@/core/config/i18n/cs";
import { locale as en } from "@/core/config/i18n/en";


Vue.use(VueI18n);

let messages = {};
messages = { ...messages, cs, en};

// get current selected language
const lang = localStorage.getItem("language") || "cs";

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: lang, // set locale
  messages // set locale messages
});

//////////////////////////////////////////////////////////////////////////////////////////////

// Modules localisation language list 
import { locale as GoodweMonitoringCS } from "@/modules/GoodweMonitoring/config/i18n/cs";
i18n.mergeLocaleMessage('cs', GoodweMonitoringCS)

import { locale as MealSystemCS } from "@/modules/MealSystem/config/i18n/cs";
i18n.mergeLocaleMessage('cs', MealSystemCS)

import { locale as ClientsCS } from "@/modules/Clients/config/i18n/cs";
i18n.mergeLocaleMessage('cs', ClientsCS)

//////////////////////////////////////////////////////////////////////////////////////////////

export default i18n;
