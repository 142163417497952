import Vue from "vue";
import Router from "vue-router";

// Module routers
import GoodweMonitoringRoutes from "./modules/GoodweMonitoring/router.js"
import MealSystemRoutes from "./modules/MealSystem/router.js"
import ClientsRoutes from "./modules/Clients/router.js"

Vue.use(Router);

export default new Router({
  mode: "history",
  linkExactActiveClass: "active",
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        ...GoodweMonitoringRoutes,
        ...MealSystemRoutes,
        ...ClientsRoutes,
        {
          name: "dashboard",
          path: "/dashboard",
          component: () => import("@/view/pages/core/Dashboard.vue")
        },
        {
          name: "users",
          path: "/users",
          component: () => import("@/view/pages/core/users/list/Users.vue")
        },
        {
          name: "users_create",
          path: "/users/create",
          component: () => import("@/view/pages/core/users/create/UserCreate.vue")
        },
        {
          path: "/users/:id",
          component: () => import("@/view/pages/core/users/detail/User.vue"),
          children: [
            {
              name: "users_overview",
              path: "/users/:id/overview",
              component: () => import("@/view/pages/core/users/detail/UserOverview.vue")
            },
            {
              name: "users_edit",
              path: "/users/:id/edit",
              component: () => import("@/view/pages/core/users/detail/UserEdit.vue")
            },
            {
              name: "users_password",
              path: "/users/:id/password",
              component: () => import("@/view/pages/core/users/detail/UserPassword.vue")
            },
            {
              name: "users_roles",
              path: "/users/:id/roles",
              component: () => import("@/view/pages/core/users/detail/UserRoles.vue")
            }
          ]
        },
        {
          path: "/profile",
          component: () => import("@/view/pages/core/profile/Profile.vue"),
          children: [
            {
              name: "profile_overview",
              path: "/profile",
              component: () => import("@/view/pages/core/profile/ProfileOverview.vue")
            },
            {
              name: "profile_edit",
              path: "/profile/edit",
              component: () => import("@/view/pages/core/profile/ProfileEdit.vue")
            },
            {
              name: "profile_password",
              path: "/profile/password",
              component: () => import("@/view/pages/core/profile/ProfilePassword.vue")
            },
          ]
        },
        {
          name: "roles",
          path: "/roles",
          component: () => import("@/view/pages/core/roles/Roles.vue")
        },
        {
          name: "positions",
          path: "/positions",
          component: () => import("@/view/pages/core/positions/Positions.vue")
        },
        {
          path: "/settings",
          redirect: "/settings/basic",
          component: () => import("@/view/pages/core/settings/Settings.vue"),
          children: [
            {
              name: "settings_basic",
              path: "/settings/basic",
              component: () => import("@/view/pages/core/settings/SettingsBasic.vue")
            },
            {
              name: "settings_company",
              path: "/settings/company",
              component: () => import("@/view/pages/core/settings/SettingsCompany.vue")
            },
            {
              name: "settings_login",
              path: "/settings/login",
              component: () => import("@/view/pages/core/settings/SettingsLogin.vue")
            },
            {
              name: "settings_emails",
              path: "/settings/emails",
              component: () => import("@/view/pages/core/settings/SettingsEmails.vue")
            },
            {
              name: "settings_apperance",
              path: "/settings/apperance",
              component: () => import("@/view/pages/core/settings/SettingsApperance.vue")
            },
          ]
        },
        {
          name: "log",
          path: "/log",
          component: () => import("@/view/pages/core/log/Log.vue")
        },
        {
          name: "tasks",
          path: "/tasks",
          component: () => import("@/view/pages/core/tasks/Tasks.vue")
        },
        {
          name: "calendar",
          path: "/calendar",
          component: () => import("@/view/pages/core/calendar/Calendar.vue")
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/view/pages/core/auth/Auth.vue"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/core/auth/Login.vue")
        },
        {
          name: "forgot_password",
          path: "/forgot-password",
          component: () => import("@/view/pages/core/auth/Forgot.vue")
        },
        {
          name: "reset_password",
          path: "/forgot-password/:token",
          component: () => import("@/view/pages/core/auth/Reset.vue")
        }
      ]
    },
    {
      path: "*",
      redirect: "/404",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/404",
          name: "404",
          component: () => import("@/view/pages/core/error/Error.vue")
        }
      ]
    }
  ]
});
