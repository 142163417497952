<template>
  <router-view></router-view>
</template>

<style lang="scss">
// 3rd party plugins css
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~socicon/css/socicon.css";
@import "~animate.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "assets/plugins/flaticon/flaticon.css";
@import "assets/plugins/flaticon2/flaticon.css";
@import "assets/plugins/keenthemes-icons/font/ki.css";
// @import "vue-select/src/scss/vue-select.scss";

// Core styles scss
@import "assets/sass/style.vue";

// Module styles scss
@import "modules/GoodweMonitoring/assets/sass/style.vue";
@import "modules/MealSystem/assets/sass/style.vue";
@import "modules/Clients/assets/sass/style.vue";

</style>

<script>
import { OVERRIDE_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "PIXIOCRM",
  metaInfo () { 
      return { title: 'No page', titleTemplate: '%s | ' + this.settings.project_name } 
  },
  data() {
    return {
      settings: {
        project_name: "PIXIO CRM",
      },
    };
  },
  beforeMount() {
    this.getBasicSettings();
  },
  mounted() {
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/core/config/layout.config.json)
     */
    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);
  },
  methods: {
    getBasicSettings() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet("/info")
        .then((response) => {
          this.settings = response.data;
        });
    }
  }
};
</script>

