// Czech
export const locale = {
    clients: {
      main: {
        name: "Evidence klientů"
      },
      menu: {

      },
      meta: {
          
      }
    }
}