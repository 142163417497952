export default [
    // Přípojná místa
    {
        name: 'goodwemonitoring_plants',
        path: '/goodwemonitoring/plants',
        component: () => import("./view/plants/list.vue"),
    },
    {
        name: 'goodwemonitoring_plant_detail',
        path: '/goodwemonitoring/plants/:id',
        component: () => import("./view/plants/detail.vue"),
    },
    // Zařízení - baterie
    {
        name: 'goodwemonitoring_batteries',
        path: '/goodwemonitoring/batteries',
        component: () => import("./view/batteries/list.vue"),
    },
    {
        name: 'goodwemonitoring_batteries_detail',
        path: '/goodwemonitoring/batteries/:id',
        component: () => import("./view/batteries/detail.vue"),
    },
    // Zařízení - Grid
    {
        name: 'goodwemonitoring_grids',
        path: '/goodwemonitoring/grids',
        component: () => import("./view/grids/list.vue"),
    },
    {
        name: 'goodwemonitoring_grids_detail',
        path: '/goodwemonitoring/grids/:id',
        component: () => import("./view/grids/detail.vue"),
    },
    // Podmínky
    {
        name: 'goodwemonitoring_conditions',
        path: '/goodwemonitoring/conditions',
        component: () => import("./view/conditions/list.vue"),
    },
    {
        name: 'goodwemonitoring_conditions_detail',
        path: '/goodwemonitoring/conditions/:id/detail',
        component: () => import("./view/conditions/detail.vue"),
    },
]