import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";
import router from "../../router";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    // Vue.axios.defaults.baseURL = "http://127.0.0.1:8000/api"; 
    Vue.axios.defaults.baseURL = (process.env.NODE_ENV === "production") ? "/backend/api" : "http://127.0.0.1:8000/api";
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
    //console.log(Vue.axios.defaults.headers);
  },

  setFileHeader() {
    Vue.axios.defaults.headers.common["Authorization"] = `Bearer ${JwtService.getToken()}`;
    Vue.axios.defaults.headers.common["Content-Type"] = 'multipart/form-data';
    //console.log(Vue.axios.defaults.headers);
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch(error => {
      // console.log(error);
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource) {
    return Vue.axios.get(`${resource}`).catch(error => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  apiGet(resource) {
    return Vue.axios.get(`${resource}`).catch(error => {
      if(error.response.status === 401){
        router.push({ name: 'login' })
        Vue.toasted.error(error.response.data.message);
      }
      if(error.response.status === 403){
        router.push({ name: 'dashboard' })
        Vue.toasted.error(error.response.data.message);
      }
      //throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },

  apiPost(resource, params) {
    return Vue.axios.post(`${resource}`, params)
      .then(response => {
        Vue.toasted.success(response.data.message);
        return response;
      });
      /*
      .catch(error => {
        if(error.response.status === 401){
          router.push({ name: 'login' })
          Vue.toasted.error(error.response.data.message);
        }
        if(error.response.status === 403){
          router.push({ name: 'dashboard' })
          Vue.toasted.error(error.response.data.message);
        }
        if(error.response.status === 422){
          return error;
        }
        return error;
        
      });*/
  },


  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return Vue.axios.delete(resource).catch(error => {
      // console.log(error);
      throw new Error(`[RWV] ApiService ${error}`);
    });
  }
};

export default ApiService;
