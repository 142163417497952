// mutation types
export const SET_USER_SEARCH = "setUserSearch";
export const SET_TASK_SEARCH = "setTaskSearch";

const state = {
   userSearch: {
       search: "",
       groups: null
   },
   taskSearch: {
    search: "",
    searchType: null,
    searchCategory: null,
    searchUser: null,
  }
};

const actions = {
};

const getters = {
  getUserSearch(state) {
    return state.userSearch;
  },
  getTaskSearch(state) {
    return state.taskSearch;
  },
};


const mutations = {
  [SET_USER_SEARCH](state, data) {
    state.userSearch = data;
  },
  [SET_TASK_SEARCH](state, data) {
    state.taskSearch = data;
  } 
};

export default {
  state,
  actions,
  mutations,
  getters
};
