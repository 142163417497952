// Czech
export const locale = {
    translator: {
      select: "Zvolte jazyk systému"
    },
    main: {
      app_name: "PIXIO CRM",
      company_name: "PIXIO s.r.o.",
      company_phone: "+420 604 639 441",
      company_email: "obchod@pixio.cz",
    },
    menu: {
      main_menu: "Hlavní menu",
      dashboard: "Nástěnka"
    },
    button: {
      save: "Uložit",
      cancel: "Zrušit",
      create: "Vytvořit",
      close: "Zavřít",
      detail: "Detail",
      back: "Zpět",
      load: "Načíst",
      permissions: "Oprávnění",
      settings: "Nastavení",
      test_connection: "Otestovat připojení",
      send_test_email: "Odeslat testovací email",
      select: "Vybrat",
      delete: "Odstranit",
      login: "Přihlásit se",
      logout: "Odhlásit se",
      restore_password: "Obnovit heslo",
      set_password: "Nastavit heslo",
      filter: "Filtrovat",
      previous: "Předchozí",
      next_step: "Další krok"
    },
    label: {
      login: "Login",
      username: "Přihlašovací jméno",
      degree_before: "Titul před",
      degree_after: "Titul za",
      company_name: "Název společnosti",
      company_in: "IČO",
      company_tin: "DIČ",
      name: "Jméno",
      surname: "Příjmení",
      email: "Email",
      phone: "Telefon",
      born_date: "Datum narození",
      web: "Web",
      street: "Ulice",
      house_number: "Číslo popisné",
      city: "Město",
      zip: "PSČ",
      sex: "Pohlaví",
      position: "Pozice",
      language: "Jazyk",
      pin: "PIN kód",
      banknumber: "Číslo účtu",
      password: "Heslo",
      password_actual: "Aktuální heslo",
      password_new: "Nové heslo",
      password_repeat: "Opakujte heslo",
      action: "Akce",
      status: "Status",
      support: "Podpora"
    },
    placeholder: {
      login: "Zadejte přihlašovací jméno",
      username: "Zadejte přihlašovací jméno",
      company_name: "Zadejte název společnosti",
      company_in: "Zadejte IČO společnosti",
      company_tin: "Zadejte DIČ společnosti",
      name: "Zadejte jméno",
      surname: "Zadejte příjmení",
      email: "Zadejte emailovou adresu",
      phone: "Zadejte telefonní číslo",
      web: "Zadejte webovou adresu",
      phone_format: "Telefon zadejte ve formátu 603123456.",
      street: "Zadejte ulici",
      house_number: "Zadejte číslo popisné",
      city: "Zadejte město",
      zip: "Zadejte PSČ",
      pin: "Zadejte PIN kód",
      banknumber: "Zadejte číslo účtu",
      bankcode: "Kód",
      password: "Zadejte heslo",
      password_actual: "Zadejte aktuální heslo",
      password_new: "Zadejte nové heslo",
      password_repeat: "Opakujte nové heslo"
    },
    title: {
      gdpr: "Ochrana osobních údajů",
      support: "Podpora",
      found: "Nalezeno {count} záznamů.",
      modules: "Moduly",
      ssl: "SSL",
      version: "verze",
      day: "den",
      days: "dny",
      days2: "dní",
      deadline: "Deadline"
    },
    validations:{
      messages:{
        required: "Toto pole je povinné.",
        min: "Je nutné zadat minimálně {length} znaky.",
        max: "Dosáhli jste maximální délky {length} znaků.",
        email: "Zadejte email ve správném tvaru.",
        numeric: "Zadejte hodnotu v číselném formátu"
      }
    },
    pagination: {
      showing: "Zobrazuji",
      records: "zázmamů",
      from: "z",
    },
    error: {
       meta: {
          title: "404 - stránka nenalezena"
        },
        title: {
          page_not_found: "Stránka nebyla nalezena"
        }
    },
    layout: {
      header: {
        management: "Správa systému",
        notifications: "Notifikace",
        calendar: "Kalendář",
        tasks: "Interní úkoly",
      },
      footer: {
        about: {
          title: "O aplikaci",
          copyright: "Všechna práva vyhrazena společnosti PIXIO s.r.o.",
          contact_link: "Kontaktní informace",
          gdpr_link: "Zásady ochrany osobních údajů",
        }
      },
      user_panel: {
        title: "Uživatelský panel",
        profile_title: "Profil",
        profile_subtitle: "Nastavení základních informací",
        password_title: "Změna hesla",
        password_subtitle: "Změna přihlašovacího hesla",
        inbox_title: "Doručená pošta",
        inbox_subtitle: "Přehled doručené pošty",
        calendar_title: "Kalendář akcí",
        calendar_subtitle: "Osobní kalendář",
        tasks_title: "Interní úkoly",
        tasks_subtitle: "Přehled interních úkolů",
      },
      quickactions: {
        title: "Rychlý přístup",
        users_title: "Uživatelé",
        users_subtitle: "Přehled uživatelů",
        role_title: "Role",
        role_subtitle: "Oprávnění systému",
        settings_title: "Nastavení",
        settings_subtitle: "Nastavení systému",
        log_title: "Log",
        log_subtitle: "Systémový log",
        position_title: "Pozice",
        position_subtitle: "Uživatelské pozice",
        enviroment_title: "Prostředí",
        enviroment_subtitle: "Kontrola prostředí",
      }
    },
    auth:
    {
      meta: {
        login: "Přihlášení do systému",
        forgot: "Zapomenuté heslo",
        reset: "Nastavení hesla"
      },
      placeholder: {
        login: "Zadejte uživatelské jméno",
        password: "Zadejte přihlašovací heslo",
        new_password: "Zadejte nové heslo",
        new_password_repeat: "Opakujte nové heslo",
      },
      title: {
        login_title: "Přihlášení",
        login_subtitle: "Přihlaste se do systému",
        forgot_password: "Zapomenuté heslo?",
        forgot_title: "Zapomenuté heslo",
        forgot_subtitle: "Obnovte si heslo k Vašemu účtu",
        reset_title: "Nastavení hesla",
        reset_subtitle: "Nastavte si nové heslo k účtu",
      },
    },
    users:{
        meta: {
          title: "Správa uživatelů",
          title_user: "Nastavení uživatele",
          title_profile: "Profil",
          title_create: "Vytvoření uživatele",
          subtitle: {
            list: "Přehled uživatelů",
            overview: "Základní přehled",
            edit: "Editace profilu",
            password: "Změna hesla",
            roles: "Nastavení oprávnění",
          }
        },
        heading: {
          list: "Přehled uživatelů",
          overview: "Základní přehled",
          roles_asign: "Zařazení do skupin",
          edit: "Editace profilu",
          password: "Změna hesla",
          roles: "Nastavení oprávnění",
          base_settings: "Základní nastavení",
          login_credentials: "Přihlašovací údaje ",
          permissions: "Oprávnění systému",
          additional_informations: "Doplňkové informace",
          recapitulation: "Rekapitulace"
        },
        subheading: {
          overview: "Přehled informací o uživateli",
          roles_asign: "Přehled přiřazených rolí uživateli",
          edit: "Nastavení základních informací",
          password: "Nastavení hesla do systému",
          roles: "Přehled oprávnění uživatele",
          base_settings: "Nastavení základních informací účtu",
          login_credentials: "Nastavení přihlašovacích údajů",
          permissions: "Nastavení oprávnění systému",
          additional_informations: "Doplňkové nastavení uživatele",
          recapitulation: "Finální přehled založeného uživatele"
        },
        button: {
          create: "Přidat uživatele",
          overview: "Přehled uživatelů",
          save_password: "Nastavit heslo",
          save_roles: "Uložit oprávnění",
          activate_account: "Aktivovat účet",
          deactivate_account: "Deaktivovat účet",
          verify_account: "Ověřit účet",
          send_verify_email: "Odeslat ověřovací email",
          avatar_crop: "Oříznout a uložit"
        },
        placeholder: {
          search: "Hledat uživatele...",
          search_roles: "Filtrace dle role"
        },
        title: {
          no_logged: "Bez přihlášení",
          not_set: "Nenastaven",
          active: "Aktivní",
          unactive: "Neaktivní",
          confirmed: "Ověřen", 
          unconfirmed: "Neověřen",
          created_date: "Datum založení",
          last_activity: "Poslední aktivita",
          password_hint: "Nové heslo by mělo obsahovat alespoň 8 znaků.",
          password_random: "V případě, že ponecháte <span class='font-weight-bold'>pole prázdné</span>, bude uživateli nastaveno <span class='font-weight-bold'>náhodné heslo</span>.",
          contact_info: "Kontaktní informace",
          additional_info: "Doplňkové informace",
          account_is_confirmed: "Účet je ověřen",
          account_is_unconfirmed: "Účet není ověřen",
          roles_assign: "Přiřazení rolí",
          individual_settings: "Individuální nastavení",
          avatar_select: "Zvolte fotografii z počítače",
          fill_base_info: "Zadejte základní informace",
          fill_login_info: "Nastavte uživateli přihlašovací údaje",
          fill_rules_info: "Nastavte oprávnění systému",
          fill_additional_info: "Nastavte doplňkové informace",
          fill_data_check: "Kontrola zadaných informací"
        }
    },
    roles: {
      meta: {
        title: "Role",
        subtitle: {
          list: "Oprávnění systému",
        }
      },
      button: {
        create: "Přidat roli",
        settings: "Nastavení",
        edit_role: "Upravit roli",
        delete_role: "Smazat roli",
        save_rule: "Uložit oprávnění"
      },
      placeholder: {
          edit_name: "Zadejte název role",
          fill_translate: "Doplňte popis {lang} varianty",
          search: "Zadejte název role..."
      },
      title: {
        global_permissions: "Globální oprávnění",
        module_permissions: "Oprávnění modulu",
        set: "Nastaveno",
        delete_role: "Smazat roli",
        confirm_delete_role_title: "Smazání role",
        confirm_delete_role_text: "Opravdu chcete odstranit tuto roli?",
        num_of_users: "Počet uživatelů",
        edit_role: "Nastavení role",
        code_name: "Kód role",
        translates: "Jazykové překlady",
      }
    },
    positions: {
      meta: {
        title: "Pozice",
        subtitle: {
          list: "Uživatelské pozice",
        }
      },
      button: {
        create: "Přidat pozici",
        settings: "Nastavení",
        edit_position: "Upravit pozici",
        delete_position: "Smazat pozici",
        save_position: "Uložit pozici"
      },
      placeholder: {
        edit_name: "Zadejte název pozice",
        fill_translate: "Doplňte popis {lang} varianty",
        search: "Zadejte název pozice..."
      },
      title: {
        set: "Nastaveno",
        delete_position: "Smazat pozici",
        confirm_delete_position_title: "Smazání pozice",
        confirm_delete_position_text: "Opravdu chcete odstranit tuto pozici?",
        num_of_users: "Počet uživatelů",
        edit_position: "Nastavení pozice",
        code_name: "Kód pozice",
        translates: "Jazykové překlady",
      }
    },
    settings: {
      meta: {
        title: "Nastavení systému",
        subtitle: {
          basic: "Základní nastavení",
          company: "Informace o společnosti",
          login: "Nastavení přihlašování",
          emails: "Nastavení emailů",
          apperance: "Personalizace vzhledu",
        }
      },
      heading: {
        basic: "Základní nastavení",
        company: "Informace o společnosti",
        login: "Nastavení přihlašování",
        emails: "Nastavení emailů",
        apperance: "Personalizace vzhledu",
      },
      subheading: {
        basic: "Nastavte základní nastavení systému",
        company: "Nastavte informace o společnosti",
        login: "Nastavte, jak se bude přihlašovat do systému",
        emails: "Nastavte, jak budete odesílat emaily ze systému",
        apperance: "Přizpůsobte si vzhled systému dle představ"
      },
      placeholder: {
        project_name: "Zadejte název aplikace",
        project_url: "Zadejte URL webu",
        tokentime: "Zadejte délku přihlášení v sekundách",
        ldap_account_suffix: "Zadejte suffix účtu ve tvaru @ad.local",
        ldap_domain_controller: "Zadejte doménový řadič ve tvaru xx.xx.local",
        ldap_base_dn: "Zadejte základní DN ve tvaru OU=AD,DC=ad,DC=local",
        ldap_port: "Port",
        email_sendername: "Název odesílatele",
        email_senderemail: "Zadejte email odesílatele",
        email_sendernoreply: "Zadejte adresu no-reply odesílatele",
        hostname: "Zadejte hostname SMTP serveru",
        host_port: "Zadejte port SMTP serveru",
      },
      title: {
        system_settings: "Nastavení systému",
        default_address: "Výchozí adresa",
        contact_info: "Kontaktní informace",
        project_name: "Název aplikace",
        project_url: "Adresa webu",
        tokentime: "Délka přihlášení",
        ldap_title: "Ověřování přes Active Directory",
        ldap_subtitle: "Tato možnost propojí CRM s externí Active Directory a umožní autorizaci účtů.",
        connection_settings: "Nastavení připojení",
        ldap_account_suffix: "Suffix účtu",
        ldap_domain_controller: "Doménový řadič",
        ldap_base_dn: "Základní DN",
        ldap_port: "Port připojení",
        administrator_account: "Účet administrátora",
        email_sendername: "Zadejte název odesílatele emailů",
        email_senderemail: "Email odesílatele",
        email_sendernoreply: "No-reply odesílatele",
        hostname: "SMTP Hostname",
        host_port: "SMTP port",
        smtp_title: "Využívat externí SMTP server",
        smtp_subtitle: "Tato možnost umožní odesílat všechny emailové zprávy skrze nadřízený SMTP server.",
        logo_main: "Hlavní logo systému",
        logo_white: "Bílá varianta logotypu",
        logo_notset: "Logo nebylo nastaveno. <br />Bude použito výchozí logo aplikace."
      }
    },
    log: {
      meta: {
        title: "Systémový log"
      }
    },
    tasks: {
      meta: {
        title: "Interní úkoly",
        subtitle: {
          list: "Přehled úkolů"
        }
      },
      button: {
        add_category: "Přidat kategorii",
        delete_category: "Smazat kategorii",
        delete_task: "Smazat úkol",
        save_task: "Uložit úkol",
        complete_tasks: "Splnit úkoly",
        delegate_tasks: "Delegovat úkoly",
        all_categories: "Všechny kategorie",
        create: "Nový úkol",
        complete_task: "Splnit úkol",
      },
      placeholder: {
        edit_cat_name: "Zadejte název kategorie",
        task_name: "Zadejte název úkolu",
        search_users: "Vyhledat uživatele",
        description: "Zadejte popis zadání úkolu",
        categories: "Vyhledat kategorie",
        search_title: "Vyhledat podle názvu..."
      },
      title: {
        menu_deadline: "Deadline",
        menu_today: "Dnes",
        menu_all_tasks: "Všechny úkoly",
        menu_my_assigned: "Mnou přiřazené",
        menu_completed: "Dokončené",
        category: "Kategorie",
        categories: "Kategorie",
        delete_category: "Smazat kategorii",
        confirm_delete_category_title: "Potvrzení smazání kategorie úkolů",
        confirm_delete_category_text: "Opravdu chcete smazat kategorii <strong>{category}</strong> ze systému?",
        confirm_edit_category_title: "Nastavení kategorie úkolů",
        confirm_create_category_title: "Vytvoření kategorie úkolů",
        confirm_complete_task_title: "Potvrzení splnění úkolu",
        confirm_complete_task_text: "Opravdu chcete splnit úkol <strong>{name}</strong>?",
        task_name: "Název úkolu",
        deadline: "Deadline úkolu",
        task_completing: "Splnění úkolu",
        date: "Datum",
        time: "Čas",
        tab_main_info: "Hlavní informace",
        tab_notifications: "Notifikace & opakování",
        tab_changelog: "Historie úprav",
        main_author: "Zadavatel",
        main_assigned: "Přiřazený řešitel",
        completed_user: "Úkol vyřešil",
        delegate: "Delegování úkolu",
        view_assigned: "Nahlížení úkolu",
        description: "Zadání úkolu",
        priority: "Priorita",
        priority_high: "Vysoká priorita",
        priority_medium: "Střední priorita",
        priority_normal: "Nízká priorita",
        priority_low: "Žádná priorita",
        notifications: "Nastavení upozornění",
        notification_7_days_before: "<strong>7 dní</strong> před dokončením",
        notification_3_days_before: "3 dny před dokončením",
        notification_24_hours_before: "<strong>24 hodin</strong> před dokončením",
        notification_12_hours_before: "12 hodin před dokončením",
        notification_6_hours_before: "6 hodin před dokončením",
        notification_1_hours_before: "1 hodinu před dokončením",
        notification_30_min_before: "<strong>30 minut</strong> před dokončením",
        notification_10_min_before: "10 minut před dokončením",
        period_function_in_progress: "Funkce <strong>Opakované úkoly</strong><br /> se připravuje.",
        edit_task: "Editovat úkol",
        show_task: "Zobrazit detail úkolu",
        task_completed: "Úkol úspěšně splněn",
        log_edit_task: "Editace úkolu",
        old_value: "Předchozí",
        new_value: "Aktuální",
        no_tasks_found: "Žádné úkoly nebyly nalezeny.",
      }
    },
    calendar: {
      meta: {
        title: "Kalendář"
      },
      button: {
        save_event: "Uložit událost",
        create_event: "Vytvořit událost",
        delete_event: "Smazat událost",
        filter: "Filtrovat",
      },
      placeholder: {
        event_name: "Zadejte název události",
        event_address: "Zadejte adresu konání"
      },
      title: {
        edit_calendar_event: "Editovat událost",
        event_name: "Název události",
        event_address: "Adresa konání",
        tab_main_info: "Hlavní informace",
        tab_peoples: "Pozvané osoby",
        date_start: "Datum počátku",
        date_end: "Datum konce",
        event_type_meeting: "Schůzka",
        event_type_call: "Hovor",
        event_type_email: "Email",
        event_type_holiday: "Dovolená",
        event_type_other: "Ostatní",
        note: "Poznámka události",
        legend: "Legenda",
        today_date: "Dnešní den",
        public_holiday: "Státní svátek",
        allday_event: "Celodenní událost"
      }
    },
    dashboard: {
      meta: {
        title: "Nástěnka"
      }
    },
  };
  