// Czech
export const locale = {
    goodwemonitoring: {
      main: {
        name: "GoodWe Monitoring"
      },
      menu: {
        basic_view: "Základní přehled",
        plants: "Přehled přípojných míst",
        batteries: "Bateriové systémy",
        devices: "Přehled zařízení",
        grids: "Síťové měniče",
        monitoring: "Monitoring",
        conditions: "Přehled podmínek",
      },
      meta: {
        plants: "Přípojná místa",
        plant_list: "Přehled přípojných míst",
        plant_detail: "Detail přípojného místa",
        devices: "Zařízení",
        devices_list: "Přehled zařízení",
        batteries_list: "Bateriové systémy",
        grids_list: "Síťové měniče",
        devices_detail: "Detail zařízení",
        battery_detail: "Detail bateriového systému",
        grid_detail: "Detail síťového měniče",
        conditions: "Podmínky",
        conditions_list: "Přehled podmínek",
        conditions_detail: "Detail podmínky",
      },
      button: {
        sync: "Synchronizovat",
      },
      placeholder: {
        search: "Zadejte hledný výraz..."
      },
      label: {
        sn: "SN",
        offline: "Offline",
        generating: "Aktivní",
        waiting: "V čekání",
        fault: "Chyba!",
      },
      title: {
        last_sync: "Poslední synchronizace"
      },
      plants: {
        label: {
          plant_name: "Název místa",
          status: "Stav",
          created_date: "Datum vytvoření",
          total_generated: "Celkem generováno",
          capacity: "Kapacita",
          total_energy: "Celkem generováno",
          plant_type: "Typ místa",
          action: "Akce",
          devices: "Zařízení",
          active: "Aktivní",
          battery_storage: "Bateriové úložiště",
          residental: "Obytný dům",
          commercial_rooftop: "Komerční objekt",
          ground_mounted: "Pozemní instalace",
          pac: "PAC",
          eday: "Denní výroba",
          total: "Výroba celkem",
          last_update: "Datum záznamu"
        },
        title: {
          log_history: "Historie hodnot",
          no_history_found: "Žádná historie nebyla nalezena.",
        }
      },
      batteries: {
        placeholder: {
          search_conditions: "Vyberte podmínku"
        },
        title: {
          heading: "Bateriové systémy",
          none_found: "Žádné baterie nebyly nalezeno. Zkuste prosím synchronizovat baterie.",
          technic_informations: "Technické informace",
          error_history: "Historie poruch",
          log_history: "Historie hodnot",
          no_history_found: "Žádná historie nebyla nalezena.",
          other_informations: "Doplňující informace",
        },
        label: {
          action: "Akce",
          serial_number: "Sériové číslo",
          model_type: "Typ modelu",
          capacity: "Kapacita",
          total_generation: "Celkem",
          status: "Status",
          firmware: "Firmware",
          arm_version: "ARM verze",
          soc: "SOC",
          temperature: "Teplota",
          check_code: "Checkcode",
          hybird: "Hybridní",
          output_voltage: "Výstupní napětí",
          output_power: "Výstupní proud",
          condition_name: "Název podmínky",
          last_update: "Datum záznamu"
        }
      },
      grids: {
        placeholder: {
          search_conditions: "Vyberte podmínku"
        },
        title: {
          heading: "Síťové měniče",
          none_found: "Žádné zařízení nebylo nalezeno. Zkuste prosím synchronizovat zařízení.",
          technic_informations: "Technické informace",
          error_history: "Historie poruch",
          log_history: "Historie hodnot",
          no_history_found: "Žádná historie nebyla nalezena.",
          ac_voltage: "[AC] - Informace - Střídavý proud",
          dc_voltage: "[DC] - Informace - Stejnosměrný proud",
        },
        label: {
          action: "Akce",
          serial_number: "Sériové číslo",
          model_type: "Typ modelu",
          capacity: "Kapacita",
          total_generation: "Celkem",
          status: "Status",
          arm_version: "ARM verze",
          temperature: "Teplota",
          check_code: "Checkcode",
          connected: "Připojeno",
          grid_power: "Grid Power",
          pv_power: "PV Power",
          hour_total: "Celkem hodin",
          daily_generation: "Dnes generováno",
          last_update: "Datum záznamu"
        }
      },
      conditions: {
        button: {
          create: "Vytvořit podmínku",
          delete_condition: "Smazat podmínku",
          save_condition: "Uložit podmínku"
        },
        placeholder: {
          search_device_type: "Vyberte typ zařízení",
          name: "Zadejte název podmínky",
          description: "Zadejte popis podmínky",
          sql_condition: "Zadejte SQL podmínku s využitím sloupců vlevo."
        },
        title: {
          name: "Název podmínky",
          device_type: "Typ zařízení",
          device_count: "Splňující podmínku",
          last_check: "Poslední kontrola",
          mail_alert: "Notifikace",
          status: "Stav",
          active: "Aktivní",
          unactive: "Neaktivní",
          confirm_delete_condition_title: "Potvrzení odstranění podmínky",
          confirm_delete_condition_text: "Opravdu chcete odstranit tuto podmínku vč. vnořených pravidel ze systému?",
          edit_condition: "Upravit podmínku",
          fill_condition: "Doplňte SQL podmínku",
          condition_info_text: "Obsah a pravidla podmínky vyplníte v detailu.",
          BATTERY: "Bateriové systémy",
          GRID: "Síťové měniče",
          sql_condition: "SQL podmínka",
          sql_condition_hint: "Zapisujte podmínku, která začíná ihned na WHERE. ",
          sql_condition_tips: "Ukázky použití",
          sql_condition_tips_1: "Vybere všechny zařízení, které mají voltage1 větší jak 5 a denní výrobu menší jak 400.",
          sql_condition_tips_2: "Vybere všechny 10K-ET, které mají ARM verzi menší jak 7."
        },
        label: {
          name: "Název podmínky",
          description: "Popis podmínky",
          device_type: "Typ zařízení",
          email_reporting: "Emailové notifikace",
          is_active: "Stav aktivnosti"
        }
      }
    }
  };
  