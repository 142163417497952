import Vue from "vue";
import i18n from "@/core/plugins/vue-i18n";
import { ValidationProvider, extend, ValidationObserver, configure } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';


Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule]);
  });

// Add a rule.
extend('secret', {
    validate: value => value === 'example',
    message: 'This is not the magic word'
});

configure({
  // this will be used to generate messages.
  defaultMessage: (field, values) => {
    console.log(values);
    values._field_ = i18n.t(`fields.${field}`);
    return i18n.t(`validations.messages.${values._rule_}`, { length: values.length });
   
  }
});

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
