// Czech
export const locale = {
    mealsystem: {
      main: {
        name: "Jídelní systém"
      },
      menu: {
        codelists: "Číselníky",
        food_management: "Správa pokrmů",
        kitchen: "Kuchyně",
        diners: "Strávníci",
        diners_list: "Přehled strávníků",
        diners_cards: "Jídelní karty",
        diners_orders: "Přehled objednávek",
        billing: "Pokladna",
        diners_transactions: "Přehled transakcí kont",
        financial_statements: "Přehled vyúčtování",
        departments: "Oddělení",
        categories: "Kategorie",
        contributors: "Přispěvatelé",
        suppliers: "Odběratelé",
        food_types: "Druhy jídel",
        warehouse: "Skladové hospodářství",
        raw_materials: "Suroviny",
        raw_materials_overview: "Přehled surovin",
        raw_material_groups: "Skupiny surovin",
        technical_materials: "MTZ",
        technical_overview: "Přehled MTZ",
        action_materials: "Akce",
        action_overview: "Přehled Akcí",
        cards_materials: "Karty",
        cards_overview: "Přehled karet",
        lock_materials: "Zámky",
        lock_overview: "Přehled zámků",
        recipes: "Receptury",
        recipes_list: "Přehled receptur",
        recipes_groups: "Skupiny receptur",
        meals: "Pokrmy",
        meals_menu: "Jídelní lístky",
        daily_issue: "Denní výdej",
      },
      meta: {
        diners: "Strávníci",
        diners_list: "Přehled strávníků",
        diners_detail: "Detail strávníka",
        diners_cards: "Jídelní karty",
        diners_orders: "Přehled objednávek",
        diners_transactions: "Přehled transakčních pohybů kont",
        diners_categories: "Kategorie",
        departments: "Oddělení",
        departments_list: "Přehled oddělení",
        contributors: "Přispěvatelé",
        contributors_list: "Přehled přispěvatelů",
        suppliers: "Odběratelé",
        suppliers_list: "Přehled odběratelů",
        foodtypes: "Druhy jídla",
        foodtypes_list: "Přehled druhů jídel",
        raw_materials: "Suroviny",
        raw_materials_list: "Přehled surovin",
        raw_material_groups: "Skupiny surovin",
        recipes: "Receptury",
        recipes_groups: "Skupiny receptur",
        recipes_list: "Přehled receptur",
        meals: "Pokrmy",
        meals_list: "Přehled pokrmů",
        meals_menu: "Jídelní lísky"

      },
      button: {
        sync: "Synchronizovat",
      },
      placeholder: {
        search: "Zadejte hledný výraz...",
        search_categories: "Výběr kategorie",
        search_departments: "Výběr oddělení",
        search_groups: "Výběr skupiny",
        search_raw_material: "Vyberte surovinu",
        search_recipe: "Vyberte recepturu",
        search_food_type: "Vyberte typ jídla"
      },
      label: {
        sn: "SN",
        offline: "Offline",
        generating: "Aktivní",
        waiting: "V čekání",
        fault: "Chyba!",
      },
      title: {
        yes: "Ano",
        no: "Ne"
      },
      categories: {
        button: {
          create: 'Přidat kategorii',
          delete_category: "Odstranit kategorii",
          save_category: "Uložit kategorii",
          create_food_type: "Přidat typ jídla"
        },
        title: {
          confirm_delete_category_title: "Potvrzení odstranění kategorie",
          confirm_delete_category_text: "Opravdu chcete odstranit vybranou kategorii ze systému? Strávnici v kategorii budou zachováni.",
          edit_category: "Upravit kategorii",
          delete_category: "Smazat kategorii",
          tab_main_info: "Hlavní informace",
          tab_food_types: "Druhy jídel",
          add_food_type: "Přidat typ jídla",
          no_foodtype_in_category: "Žádný typ jídla nebyl v kategorii zařazen."
        },
        placeholder: {
          name: "Zadejte název kategorie",
          coeficient: "Zadejte koeficient porce",
          categories: "Vyberte kategorii",
          buy_price: "Zadejte nákupní cenu",
          sell_price: "Zadejte prodejní cenu"
        },
        label: {
          name: "Název kategorie",
          coeficient: "Koeficient",
          age_range: "Věkové rozpětí",
          type: "Typ kategorie",
          allow_to_minus: "Povolit mínusové konto",
          parameters: "Parametry",
          category_type: "Typ kategorie",
          account_to_minus: "Mínusové konto",
          active: "Aktivní",
          foodname: "Název jídla",
          buy_price: "Nákupní cena",
          sell_price: "Prodejní cena",
          allowed_orders: "Povoleno objednávat",
          allowed_from_internet: "Objednávky přes internet",
          allowed_from_terminal: "Objednávky přes terminál"
        }
      },
      departments: {
        button: {
          create: "Přidat oddělení",
          delete_department: "Odstranit oddělení",
          save_department: "Uložit oddělení",
        },
        title: {
          confirm_delete_department_title: "Potvrzení odstranění oddělení",
          confirm_delete_department_text: "Opravdu chcete odstranit vybrané oddělení ze systému? <br /> Strávníci v oddělení budou zachováni.",
          edit_department: "Upravit oddělení",
          delete_department: "Smazat oddělení",
        },
        placeholder: {
          name: "Zadejte název oddělení",
        },
        label: {
          name: "Název oddělení",
        }
      },
      contributors: {
        button: {
          create: "Přidat přispěvatele",
          delete_contributor: "Odstranit přispěvatele",
          save_contributor: "Uložit přispěvatele",
        },
        title: {
          confirm_delete_contributor_title: "Potvrzení odstranění přispěvatele",
          confirm_delete_contributor_text: "Opravdu chcete odstranit vybraného přispěvatele ze systému?",
          edit_contributor: "Upravit přispěvatele",
          delete_contributor: "Smazat přispěvatele",
          main_info: "Základní informace",
          contact_informations: "Kontaktní informace",
        },
        placeholder: {
          name: "Zadejte název přispěvatele",
          in: "Zadejte IČO",
          tin: "Zadejte DIČ",
          email: "Zadejte email",
          phone: "Zadejte telefon",
          street: "Zadejte ulici",
          house_number: "Zadejte č.p.",
          city: "Zadejte město",
          zip: "Zadejte PSČ",
        },
        label: {
          name: "Název",
          phone: "Telefon",
          email: "Email",
          in: "IČO",
          tin: "DIČ",
          street: "Ulice",
          house_number: "Č.p.",
          city: "Město",
          zip: "PSČ",
        }
      },
      suppliers: {
        button: {
          create: "Přidat odběratele",
          delete_supplier: "Odstranit odběratele",
          save_supplier: "Uložit odběratele",
        },
        title: {
          confirm_delete_supplier_title: "Potvrzení odstranění odběratele",
          confirm_delete_supplier_text: "Opravdu chcete odstranit vybraného odběratele ze systému?",
          edit_supplier: "Upravit odběratele",
          delete_supplier: "Smazat odběratele",
          main_info: "Základní informace",
          contact_informations: "Kontaktní informace",
        },
        placeholder: {
          name: "Zadejte název odběratele",
          in: "Zadejte IČO",
          tin: "Zadejte DIČ",
          email: "Zadejte email",
          phone: "Zadejte telefon",
          street: "Zadejte ulici",
          house_number: "Zadejte č.p.",
          city: "Zadejte město",
          zip: "Zadejte PSČ",
        },
        label: {
          name: "Název",
          phone: "Telefon",
          email: "Email",
          in: "IČO",
          tin: "DIČ",
          street: "Ulice",
          house_number: "Č.p.",
          city: "Město",
          zip: "PSČ",
        }
      },
      foodtypes: {
        button: {
          create: "Přidat druh jídla",
          delete_foodtype: "Odstranit druh",
          save_foodtype: "Uložit druh jídla",
        },
        title: {
          confirm_delete_foodtype_title: "Potvrzení odstranění druhu jídla",
          confirm_delete_foodtype_text: "Opravdu chcete odstranit vybraný druh jídla ze systému?",
          edit_foodtype: "Upravit druh jídla",
          delete_foodtype: "Smazat druh jídla",
        },
        placeholder: {
          name: "Zadejte název druhu jídla",
          issue_from: "Výdej od",
          issue_to: "Výdej do",
          order_to: "Objednávky do",
          cancel_to: "Odhlášky do"
        },
        label: {
          name: "Název druhu",
          parameters: "Parametry",
          issue_times: "Časy výdeje",
          orders_to: "Objednávky do",
          cancels_to: "Storno do",
          issue_range: "Časové rozpětí výdeje"
        }
      },
      diners: {
        button: {
          create: "Přidat strávníka",
          fill_conto: "Přidat",
          withdraw_conto: "Vybrat",
          unpair_card: "Odebrat",
          change_card: "Změnit",
          assign_card: "Přiřadit",
          delete_card: "Odebrat",
          save_diner: "Uložit strávníka",
          show_all: "Zobrazit vše"
        },
        title: {
          unactive: "Neaktivní",
          active: "Aktivní",
          diner_no_assigned_card: "Strávník nemá přiřazenou kartu.",
          confirm_unpair_card_title: "Potvrzení odstranění karty u strávníka",
          confirm_unpair_card_text: "Opravdu chcete u strávníka odebrat kartu? V přehledu karet tato kartu nadále zůstane.",
          change_conto_balance: "Změna stavu konta",
          balance: "Aktuální stav konta",
          change_card: "Změna přiřazení karty",
          transaction_log_history: "Transakční historie konta",
          diner_no_tansaction_history: "Strávník nemá žádnou transakční historii.",
          orders_history: "Historie objednávek",
          orders_on_day: "Na datum",
          orders_kind: "Druh",
          orders_code: "Kód",
          orders_meal_name: "Název jídla",
          orders_portion: "Porce",
          orders_price: "Cena",
          orders_source: "Zdroj",
          orders_ordered: "Objednáno",
          download_document: "Stáhnout doklad"
        },
        placeholder: {
          amount: "Zadejte požadovanou částku",
          types: "Zvolte typ akce",
          kind_od_payments: "Vyberte typ platby",
          cards: "Vyberte kartu",
          discarted: "Aktivní / vyřazený strávník",
          diner_number: "Zadejte číslo strávníka"
        },
        label: {
          diner_number: "Číslo strávníka",
          diner_fullname: "Jméno strávníka",
          category: "Kategorie",
          department: "Oddělení",
          balance: "Zůstatek",
          status: "Status",
          amount: "Částka",
          conto_type: "Typ akce",
          conto_kind_of_payment: "Typ platby",
          search_card: "Vyhledávání karty",
          discarted: "Aktivní",
          allow_to_minus: "Povolit mínusové konto",
        }
      },
      cards: {
        button: {
          create: "Přidat kartu",
          delete_card: "Odstranit kartu",
          save_card: "Uložit kartu",
        },
        title: {
          delete_card: "Odstranit kartu",
          confirm_delete_card_title: "Potvrzení odstranění karty",
          confirm_delete_card_text: "Opravdu chcete odstranit tuto kartu ze systému? Akce je nevratná.",
          edit_card: "Upravit kartu",

        },
        placeholder: {
          card_number: "Číslo karty",
          hex_code: "HEXa kód karty"
        },
        label: {
          card_number: "Číslo karty",
          hex_code: "Hexa kód",
          assigned_dinner: "Přiřazený strávník"
        }
      },
      transactions: {
        placeholder: {
          search_diners: "Vyhledávání strávníka",
          types: "Typ akce",
          date_search: "Zadejte rozpětí"
        },
        label: {
          amount: "Částka",
          type: "Typ",
          kind_of_payment: "Druh platby",
          diner_name: "Strávník",
          created_at: "Datum akce"
        }
      },
      orders: {
        placeholder: {
          search_diners: "Vyhledávání strávníka",
          date_search: "Zadejte rozpětí"
        },
        label: {


          diner_name: "Strávník",
          created_at: "Datum akce"
        }
      },
      rawmaterials: {
        button: {
          fill_stock: "Naskladnit surovinu",
          delete_item: "Odstranit surovinu",
          save_item: "Uložit surovinu",
          create: "Přidat surovinu",
          groups: "Skupiny surovin",
          create_group: "Přidat skupinu",
          delete_group: "Odstranit skupinu",
          save_group: "Uložit skupinu"
        },
        title: {
          delete_item: "Odstranit surovinu",
          edit_item: "Upravit surovinu",
          confirm_delete_item_title: "Potvrzení odstranění suroviny",
          confirm_delete_item_text: "Opravdu chcete odstranit surovinu ze systému?",
          fill_stock: "Naskladnění suroviny",
          stock_log: "Historie naskladnění",
          delete_group: "Odstranit skupinu",
          edit_group: "Upravit skupinu",
          confirm_delete_group_title: "Potvrzení odstranění skupiny",
          confirm_delete_group_text: "Opravdu chcete odstranit skupinu surovin?",
        },
        label: {
          name: "Název suroviny",
          item_number: "Kód",
          category: "Kategorie suroviny",
          stock: "Skladové informace",
          amount: "Počet",
          price: "Cena",
          filled_at: "Naskladněno dne",
          min_use: "Minimální balení",
          group_name: "Název skupiny"
        },
        placeholder: {
          grouo_name: "Zadejte název skupiny",
          amount: "Zadejte počet naskladněné suroviny",
          price: "Zadejte naskladňovací cenu suroviny",
          name: "Zadejte název suroviny",
          item_number: "Zadejte kód suroviny",
          group: "Zadejte skupinu suroviny",
          min_use: "Zadejte minimální počet v balení"
        }
      },
      recipes: {
        button: {
          create_group: "Přidat skupinu",
          create: "Přidat recepturu",
          groups: "Skupiny receptur",
          delete_group: "Odstranit skupinu",
          save_group: "Uložit skupinu",
          delete_recipe: "Odstranit recepturu",
          save_recipe: "Uložit recepturu",
          create_raw_material: "Přidat surovinu"
        },
        title: {
          delete_group: "Odstranit skupinu",
          edit_group: "Upravit skupinu",
          delete_recipe: "Odstranit recepturu",
          edit_recipe: "Upravit recepturu",
          confirm_delete_group_title: "Potvrzení odstranění skupiny",
          confirm_delete_group_text: "Opravdu chcete odstranit skupinu receptur?",
          confirm_delete_recipe_title: "Potvrzení odstranění receptury",
          confirm_delete_recipe_text: "Opravdu chcete odstranit recepturu?",
          tab_main_info: "Hlavní informace",
          tab_assigned_raw_materials: "Přiřazené suroviny",
          no_raw_material_in_recipe: "Žádná surovina nebyla k receptuře přiřazena.",
          add_raw_material: "Přidat surovinu"
        },
        label: {
          name: "Název skupiny",
          recipe_number: "Číslo receptury",
          recipe_name: "Název receptury",
          group: "Skupina",
          heat_treatment: "Tepelná úprava",
          prepared_quantity: "Připravované množství",
          meal_count: "Počet porcí",
          raw_material: "Surovina",
          amount: "Množství"
        },
        placeholder: {
          name: "Zadejte název skupiny",
          recipe_name: "Zadejte název receptury",
          recipe_number: "Zadejte číslo receptury",
          prepared_quantity: "Zadejte počet připravovaného množství v g",
          meal_count: "Zadejte počet připravovaných porcí",
          heat_treatment: "Vyberte typ tepelné přípravy",
          group: "Zvolte skupinu",
          amount: "Zadejte množství (g)"

        }
      },
      meals: {
        button: {
          create: "Vytvořit pokrm",
          delete_meal: "Odstranit pokrm",
          save_meal: "Uložit pokrm",
          create_raw_recipe: "Přidat recepturu"
        },
        placeholder: {
          name: "Zadejte název pokrmu"
        },
        label: {
          name: "Název pokrmu",
          recipes: "Receptura",
          amount: "Poměr"
        },
        title: {
          edit_meal: "Upravit pokrm",
          delete_meal: "Smazat pokrm",
          confirm_delete_recipe_title: "Potvrzení odstranění pokrmu",
          confirm_delete_recipe_text: "Opravdu chcete odstranit pokrm?",
          tab_main_info: "Hlavní informace",
          tab_assigned_recipes: "Přiřazené receptury",
          no_receips_in_recipe: "Žádná receptura nebyla k pokrmu přiřazena.",
          add_receipe: "Přidat recepturu"
        }
      }
    }
  };
  