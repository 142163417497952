export default [
    // Strávníci
    {
        name: 'mealsystem_diners',
        path: '/meal-system/diners/list',
        component: () => import("./view/diners/list.vue"),
    },
    {
        name: 'mealsystem_diners_cards',
        path: '/meal-system/diners/cards',
        component: () => import("./view/diners/cards.vue"),
    },
    {
        name: 'mealsystem_diners_orders',
        path: '/meal-system/diners/orders',
        component: () => import("./view/diners/orders.vue"),
    },
    {
        name: 'mealsystem_billing_transactions',
        path: '/meal-system/billing/transactions',
        component: () => import("./view/billing/transactions.vue"),
    },
    {
        name: 'mealsystem_financial_statements',
        path: '/meal-system/billing/financial-statements',
        component: () => import("./view/billing/financial-statements.vue"),
    },
    {
        name: 'mealsystem_diners_new',
        path: '/meal-system/diners/new',
        component: () => import("./view/diners/detail.vue"),
    },
    {
        name: 'mealsystem_diners_detail',
        path: '/meal-system/diners/:id',
        component: () => import("./view/diners/detail.vue"),
    },
    {
        name: 'mealsystem_departments',
        path: '/meal-system/departments',
        component: () => import("./view/departments/list.vue"),
    },
    {
        name: 'mealsystem_categories',
        path: '/meal-system/categories',
        component: () => import("./view/diners/categories.vue"),
    },
    {
        name: 'mealsystem_contributors',
        path: '/meal-system/contributors',
        component: () => import("./view/contributors/list.vue"),
    },
    {
        name: 'mealsystem_suppliers',
        path: '/meal-system/suppliers',
        component: () => import("./view/suppliers/list.vue"),
    },

    // Skladové hospodářství
    {
        name: 'raw_materials',
        path: '/meal-system/raw-materials',
        component: () => import("./view/warehouse/raw-materials/list.vue"),
    },
    {
        name: 'raw_materials_groups',
        path: '/meal-system/raw-materials/groups',
        component: () => import("./view/warehouse/raw-materials/groups.vue"),
    },

    
    // TODO - další sklady


    {
        name: 'mealsystem_food_types',
        path: '/meal-system/food-types',
        component: () => import("./view/foodtypes/list.vue"),
    },
    {
        name: 'recipes_list',
        path: '/meal-system/recipes',
        component: () => import("./view/recipes/list.vue"),
    },
    {
        name: 'recipes_groups',
        path: '/meal-system/recipes/groups',
        component: () => import("./view/recipes/groups.vue"),
    },
    {
        name: 'meals',
        path: '/meal-system/meals',
        component: () => import("./view/meals/list.vue"),
    },
    {
        name: 'meal-menu',
        path: '/meal-system/meals-menu',
        component: () => import("./view/meal-menu/menu.vue"),
    },
]